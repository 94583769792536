<template>
  <div class="detail">
    <van-nav-bar
        title="Card Details"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="main">
      <div class="item">
        <div class="label">Card Name</div>
        <div class="value">{{ cardInfo.u_name }}</div>
      </div>
      <div class="item">
        <div class="label">Login E-mail</div>
        <div class="value">{{ cardInfo.login_email }}</div>
      </div>
      <div class="item">
        <div class="label">Contact E-mail</div>
        <div class="value">{{ cardInfo.email }}</div>
      </div>
      <div class="item">
        <div class="label">International Mobile Number</div>
        <div class="value">{{ cardInfo.mobile }}</div>
      </div>
      <div class="item">
        <div class="label">Card Number</div>
        <div class="value">{{ cardInfo.bank_card_code }}</div>
      </div>
      <div class="item">
        <div class="label">Address</div>
        <div v-if="cardInfo.country_name" class="value">
          {{ cardInfo.country_name + ' ' + cardInfo.area_line_1 + ' ' + cardInfo.area_line_2 + ' ' + cardInfo.city + ' ' + cardInfo.province }}
        </div>
      </div>
    </div>
  </div>

  <TabBar/>
</template>

<script setup>
  import TabBar from "@/components/TabBar";
  import { useRoute, useRouter } from 'vue-router'
  import { getCardDetail } from '@/request/api'
  import { ref } from 'vue'

  const router = useRouter()
  const route = useRoute()
  const id = route.query.id
  const cardInfo = ref({})
  getCardDetail({
    address: sessionStorage.getItem('address'),
    id
  }).then(res => {
    if(res.success) {
      cardInfo.value = res.data
    }
  })
</script>

<style lang="less" scoped>
.detail{


  .main{
    padding: 24px;

    .item{
      margin-bottom: 12px;

      .label{
        font-size: 14px;
        line-height: 20px;
        color: #697377;
        margin-bottom: 8px;
      }
      .value{
        font-size: 16px;
        line-height: 24px;
        color: #F8F8F8;
        border-bottom: 1px solid #06C8A1;
      }
    }
  }
}
</style>
